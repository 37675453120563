import _logger, { LOGGER_CONFIG_DEFAULT } from '../utils/logger';
import React, { useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';
import styled from "@emotion/styled";
import type { NextPage } from 'next'
import Head from 'next/head'
import styles from '../../../styles/Home.module.css'
import DynamicCard from '../widgets/DynamicCard/DynamicCard';
import { ICardAttributes } from '../widgets/CardInterface';
import fs from 'fs';
import { WidgetAction } from '../widgets/WidgetInterface';

const logger = _logger.newLogger({ name: _logger.getFilename(__filename), ...LOGGER_CONFIG_DEFAULT });
logger.verbose("MODULE LOADED");

const PageContainer = styled.div`
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      overflow: visible;
      user-select: none;

      background-repeat: cover;
`;
/* background: ${LinenBackgroundColor}; */
/* background-color: ${LinenBackgroundColor}; */
/* background-image: url(${LinenBackgroundImagePath}); */

interface ILayoutProps {
  cardId?: string;
  shareId?: string;
  page?: string;
  cardAttributes: ICardAttributes
}

// This gets called on every request
// export async function getServerSideProps() {
export async function getStaticProps() {
  // Pass data to the page via props
  const cardAttributes = await require('./indexLayout.json');
  return { props: { cardAttributes } }
}

// export async function getStaticPaths() {
//   return {
//     paths: ["/app/card"],
//     fallback: false
//   }
// }

const APP_PATH = "app";

const LayoutPage: NextPage<ILayoutProps> = (props) => {
  const router = useRouter();

  //
  // TODO: Confirm if we need to defer all actions that access this widget's context (e.g. useState).
  // It's safer to defer ALL actions.
  //
  const actionQueue = useRef<WidgetAction[]>([]);
  const [processActionQueue, setProcessActionQueue] = useState<{}>(); // Usage: setProcessActionQueue({});
  const actionDispatcher = useCallback((action?: WidgetAction, deferAction: boolean = false) => {

    const doAction = (action: WidgetAction) => {
      let url: string | undefined;
      switch (action?.action) {

        case "Navigate":
          const match = action.url.match(/^page:\/\/(.*)/);
          if (match?.[1]) {
            // Navigate to page
            const pagePath = match?.[1];
            // url = cardIdRef.current ? `${APP_PATH}/card/${cardIdRef.current}/${pagePath}` : `sid/${shareIdRef.current}/${pagePath}`
            url = props.cardId ? `${APP_PATH}/card/${props.cardId}/${pagePath}` : `sid/${props.shareId}/${pagePath}`
            window.history.pushState(null, "New Page Title", url);
          } else {
            const match = action.url.match(/^app:\/\/(.*)/);
            if (match?.[1]) {
              // Navigate to page
              url = `/${APP_PATH}/${match?.[1]}`;
              const as = url;
              router.push(url, as, {});
              // window.history.pushState(null, url, `${APP_PATH}/${url}`);
            } else {
              // Navigate to url
              url = action.url;
              switch (action.target) {
                // case "_blank":
                //   if (INSTALLED) {
                //     window.location.href = url;
                //   } else {
                //     window.open(url, action.target || "_blank", action.features);
                //   }
                //   break;
                // case "_embed":
                //   setPopupDialog(
                //     <PopDialog
                //       src={url}
                //       height={"100%"}
                //       sliderDirection={PopDirection.FromBottom}
                //       visible={true}
                //       onExit={() => setPopupDialog(null)}
                //     />);
                //   break;
                case undefined:
                default:
                  window.location.href = url;
                  break;

              }
            }
            // if (action.target === "_modal") {
            //     setPopupDialog(<PopDialog src={url} height={"100%"} sliderDirection={PopDirection.FromBottom} visible={true} />);
            // } else if (action.target === undefined) {
            //     window.location.href = url;
            // } else {
            //     window.open(url, action.target || "_blank", action.features);
            // }
            // window.open( url, "_blank")?.focus();
          }

          // const pageParams = { view: urlParams.view, p1: urlParams.p1, p2: action.pagePath };
          // loadMainContent(pageParams);
          // window.location.href = `${hydratedVarsRef.current?.cardLocation}/${action.pagePath}`;
          break;

        // case "NavigateToPage":
        //     window.history.pushState(null, "New Page Title", `card/${urlParams.p1}/${action.pagePath}`);
        //     // const pageParams = { view: urlParams.view, p1: urlParams.p1, p2: action.pagePath };
        //     // loadMainContent(pageParams);
        //     // window.location.href = `${hydratedVarsRef.current?.cardLocation}/${action.pagePath}`;
        //     break;

        case "SetBackground":
          // setBackgroundNode(action.content);
          break;

        case "SetOverlay":
          // setOverlayNode(action.content);
          break;
      }
    }

    // Push new action
    action && actionQueue.current.push(action);

    // Defer or process pending queue
    if (deferAction) {
      setProcessActionQueue({});
    } else {
      // Process all pending actions
      // logHookExecution && logger.debug(`[${_contextKey}] process actionQueue[${actionQueue.current.length}]`);
      while (actionQueue.current.length > 0) {
        const action = actionQueue.current.shift();
        action && doAction(action);
      }
    }
  }, []);

  // Process pending queueu
  useEffect(() => {
    actionDispatcher();
  }, [processActionQueue]);


  return (
    <Suspense fallback={null}>
      {props.cardAttributes?.layout
        ? <>
          <Head>
            <title>{props.cardAttributes?.layout?._title || ""}</title>
            <meta name='description' content={"Meet Deets App"} />
            <meta property="og:title" content={`Meet Deets`} />
            <meta property="og:description" content={"Meet Deets App"} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta charSet="utf-8" />
            <link rel="icon" href="/favicon.ico" />
          </Head>

          <PageContainer>
            {/* <div style={{ position: "fixed", left: 0, top: 0, width: "100%", height: "auto", zIndex: 10000, color: "yellow" }}>{router.pathname}<br /></div> */}
            <DynamicCard cardAttributes={props.cardAttributes} actionDispatcher={actionDispatcher} />
          </PageContainer>
        </>
        : null}
    </Suspense>
  );
}

export default LayoutPage
